import React, { MutableRefObject, PropsWithChildren, useContext, useRef } from 'react'
import { Header } from '../Header'
import { Footer } from '../Footer'
import './index.scss'
import classNames from 'classnames'
import { Dayjs } from 'dayjs'
import useMobile from '../../hooks/useMobile'


export const PageContext = React.createContext({
    headerHeight: 0
})

export const Content: React.FC<PropsWithChildren<{ noMargin: boolean }>> = function ({ children, noMargin }) {
    const { headerHeight } = useContext(PageContext)
    return (
        <div className='page-content' style={{ marginTop: noMargin ? 0 : headerHeight }}>{children}</div>
    )
}

export const Page: React.FC<PropsWithChildren<{ header?: { viewOnly?: boolean, transparent?: boolean, notice?: { expired: Dayjs, content: React.ReactNode, link?: string } }, className?: string }>> = function ({ children, header, className }) {
    const [headerHeight, setheaderHeight] = React.useState(80);
    const ref = useRef<any>()
    const { isMobile } = useMobile()

    React.useEffect(() => {
        if (ref.current) {
            const height = ref.current.offsetHeight;
            setheaderHeight(height)
        }
    }, [isMobile])


    return (
        <div className={classNames('page', header?.transparent ? 'no-margin' : '', className)}>
            <Header ref={ref} {...header} />
            <PageContext.Provider value={{ headerHeight }}>
                <Content noMargin={!!header?.transparent}>
                    {children}
                </Content>
            </PageContext.Provider>
            <Footer />
        </div>
    )
}