import React from 'react'
import { Button as AntdButton, ButtonProps } from 'antd'
import { HashLink } from 'react-router-hash-link';
import './index.scss'

export const Button: React.FC<ButtonProps & { to?: string}> = function ({ children, type, to, target,onClick }) {
    if (type === 'link' && to) {
        return (
            <HashLink smooth className='button' to={to} target={target} onClick={onClick}>{children}</HashLink>
        )
    }
    return (
        <AntdButton className='button' onClick={onClick}>{children}</AntdButton>
    )
}