import i18next from "i18next";
import en from './en'

i18next.init({
    resources: {
        en: {
            translation: en,
        }
    },
    lng: "en",
});

export default i18next