import React from 'react'
import classNames from 'classnames'
import './index.scss'
import useMobile from '../../hooks/useMobile'


export const Section: React.FC<Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> & { size?: 'small' | 'middle' | 'large', containerClass?: string }> = function (props) {
    const { isMobile } = useMobile()
    
    const { size = isMobile ? 'middle' : 'large', children, className, containerClass, ...restProps } = props
    return (
        <div className={classNames('section-container', size, containerClass)}>
            <div {...restProps} className={classNames('section', className)}>
                {children}
            </div>
        </div>
    )
}