import { useTranslation } from 'react-i18next'
import { Bg as BannerBg } from './bg'
import { Button } from '../Button'
import { TrailAnimate, enterDown } from '../Animate'
import { PropsWithChildren } from 'react'
import './index.scss'
import useMobile from '../../hooks/useMobile'
import classNames from 'classnames'

export const Banner: React.FC<PropsWithChildren<{ h1?: string, p?: React.ReactNode, button?: React.ReactElement, bg?: React.ReactElement, animate?: boolean, fixHeight?: boolean }>> = function ({ children, h1, p, button, animate, bg, fixHeight = true }) {
    const { isMobile } = useMobile()
    const content = animate ? (
        <TrailAnimate className='left' createAnimate={enterDown} triggerOnce>
            <h1>{h1}</h1>
            <p>{p}</p>
            {!isMobile && button}
        </TrailAnimate>
    ) : (
        <div className='left'>
            <h1>{h1}</h1>
            <p>{p}</p>
            {!isMobile && button}
        </div>
    )
    return (
        <div className={classNames('banner-container', fixHeight ? 'fix-height' : '')}>
            <div className={'section-container large'}>
                <div className={'section banner'}>
                    {content}
                    {children}
                    {isMobile && button}
                </div>
            </div>
            {bg || <BannerBg animate={animate} />}
        </div>
    )
}