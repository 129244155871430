import React, { useContext } from 'react'
import { Page, PageContext } from '../Page'
import { motion, Variants } from 'framer-motion';
import './index.scss'
import { Header as icons } from '../../assets/images'

const duration = 1
const pathLength = 2 * Math.PI * 30 * 0.9;
const pathVariants: Variants = {
    hidden: {
        strokeDasharray: pathLength,
        strokeDashoffset: pathLength,
        rotate: '-90deg',
        transition: {
            duration,
            ease: "easeInOut",
        },
    },
    visible: {
        strokeDasharray: pathLength,
        strokeDashoffset: [pathLength, 0, -pathLength],
        rotate: '270deg',
        transition: {
            duration: duration * 2,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "loop",
        },
    },
};


const LoadingContainer = function () {
    const { headerHeight } = useContext(PageContext)
    return (
        <div className='loading-container' style={{ height: `calc(100vh - ${headerHeight}px)` }}>
            <motion.svg
                initial={"hidden"}
                animate="visible"
                viewBox="0 0 64 64"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <motion.g clip-path="url(#clip0_745_3105)">
                    <path d="M32 58C46.3594 58 58 46.3594 58 32C58 17.6406 46.3594 6 32 6C17.6406 6 6 17.6406 6 32C6 46.3594 17.6406 58 32 58Z" fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21 21.6661C21 21.2982 21.2982 21 21.6661 21H36.9671C39.7393 21 42.0766 22.8627 42.7956 25.4047H34.2141C32.9978 25.4047 32.0118 26.3907 32.0118 27.6071H42.9988C42.8348 29.4271 41.8656 31.015 40.4501 32.0118C42.0066 33.1078 43.0235 34.9187 43.0235 36.9671C43.0235 40.312 40.312 43.0235 36.9671 43.0235H34.2141V27.6071H32.0118V43.0235H27.6071V32.0118H25.4047V43.0235H21.0428C21.0192 43.0235 21 43.0043 21 42.9807V21.6661ZM26.5059 24.3035C25.2895 24.3035 24.3035 25.2895 24.3035 26.5059C24.3035 27.7222 25.2895 28.7082 26.5059 28.7082C27.7222 28.7082 28.7082 27.7222 28.7082 26.5059C28.7082 25.2895 27.7222 24.3035 26.5059 24.3035Z" fill="white" />
                </motion.g>
                <g clip-path="url(#clip0_745_3105)">
                    <motion.circle variants={pathVariants} cx="32" cy="32" r="30" stroke="black" strokeWidth="3" strokeLinecap={'round'} />
                </g>
                <defs>
                    <clipPath id="clip0_745_3105">
                        <rect width="64" height="64" fill="white" />
                    </clipPath>
                </defs>
            </motion.svg>
        </div>
    )
}
export const Loading = function () {

    return (
        <div className='page'>
            <div className={`header-container`}>
                <header style={{ height: 80 }}>
                    <a className="logo">
                        <img src={icons.Logo} />
                        <span>BiT Global</span>
                    </a>
                </header>
            </div>
            <LoadingContainer />
        </div>
    )
}