import { PropsWithChildren } from 'react'
import arrow from '../../assets/images/global/arrow.svg'
export const Notice: React.FC<PropsWithChildren<{ link?: string }>> = function ({ children, link }) {
    return (
        <div className='notice-bg'>
            <div className='notice'>
                <div className='left'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M13 7.22055L9.60282 10.0001H6V14.0001H9.60282L13 16.7796V7.22055ZM8.88889 16.0001H5C4.44772 16.0001 4 15.5524 4 15.0001V9.00007C4 8.44778 4.44772 8.00007 5 8.00007H8.88889L14.1834 3.66821C14.3971 3.49335 14.7121 3.52485 14.887 3.73857C14.9601 3.8279 15 3.93977 15 4.05519V19.945C15 20.2211 14.7761 20.445 14.5 20.445C14.3846 20.445 14.2727 20.4051 14.1834 20.332L8.88889 16.0001ZM18.8631 16.5912L17.4411 15.1691C18.3892 14.4377 19 13.2902 19 12.0001C19 10.5698 18.2493 9.31475 17.1203 8.60766L18.5589 7.16906C20.0396 8.26166 21 10.0188 21 12.0001C21 13.8423 20.1698 15.4905 18.8631 16.5912Z" fill="#FAFAFA" />
                    </svg>
                    <div className='content'>{children}</div>
                </div>
                {link && (
                    <a className='right' target='_blank' href={link}>
                        <span className='link-text'>Learn more</span>
                        <img src={arrow} />
                    </a>
                )}
            </div>
        </div>
    )
}