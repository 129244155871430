import React, { PropsWithChildren } from 'react';
import { useSpring, animated, useTrail } from 'react-spring';
import { useInView } from 'react-intersection-observer';


export const enterDown = (inView: boolean) => {
    return {
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(60px)'
    }
}

export const enterLeft = (inView: boolean) => {
    return {
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateX(0)' : 'translateX(-60px)'
    }
}

export const enterLeftDown = (inView: boolean) => {
    return {
        opacity: inView ? 1 : 0,
        transform: inView ? 'translate(0,0)' : 'translate(-60px,-60px)'
    }
}

export const enterRight = (inView: boolean) => {
    return {
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateX(0)' : 'translateX(120px)'
    }
}

export const Animate: React.FC<React.InputHTMLAttributes<HTMLDivElement> & { createAnimate?: (inView: boolean) => Parameters<typeof useTrail>[1], threshold?: number, triggerOnce?: boolean }> = ({ style, children, createAnimate = enterDown, threshold = 0.1, triggerOnce = true, ...restProps }) => {
    const [ref, inView] = useInView({ triggerOnce, threshold });
    const animation = useSpring(createAnimate(inView));

    return (
        <animated.div {...restProps} ref={ref} style={Object.assign(animation, style)}>
            {children}
        </animated.div>
    );
};

export const TrailAnimate: React.FC<PropsWithChildren<{ itemClass?: string, className?: string, speed?: 'slow' | 'fast', createAnimate?: (inView: boolean) => Parameters<typeof useTrail>[1], threshold?: number, triggerOnce?: boolean, style?: React.CSSProperties }>> = ({ children, itemClass, className, style, speed = 'fast', createAnimate = enterDown, threshold = 0.1, triggerOnce = true }) => {
    const [ref, inView] = useInView({ triggerOnce, threshold });

    const childrenLength = React.Children.count(children)
    const newChildren = React.Children.toArray(children)

    const trail = useTrail(childrenLength, {
        config: speed === 'fast' ? { mass: 2, tension: 170, friction: 20 } : { mass: 2, tension: 120, friction: 30 },
        ...createAnimate(inView)
    });

    return (
        <div ref={ref} className={className} style={style}>
            {trail.map((style, index) => (
                <animated.div key={index} style={style} className={itemClass}>
                    {newChildren[index]}
                </animated.div>
            ))}
        </div>
    );
};