import React from 'react';
import { motion } from 'framer-motion';

const svgVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: { duration: 1 }
    }
};

const pathVariants = {
    hidden: {
        pathLength: 0,
        fill: "rgba(255, 255, 255, 0)"
    },
    visible: {
        pathLength: 1,
        fill: "rgba(255, 255, 255, 1)",
        transition: {
            duration: 1,
            ease: "easeInOut"
        }
    }
};

export const Bg: React.FC<{ animate?: boolean }> = ({ animate }) => (
    <motion.svg
        className='banner-bg'
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 522"
        fill="none"
        preserveAspectRatio="xMidYMin meet"
        variants={svgVariants}
        initial={animate ? "hidden" : "visible"}
        animate="visible">
        <g clipPath="url(#clip0_249_2568)">
            <motion.path variants={pathVariants} d="M1349 83L1948 231" stroke="url(#paint0_linear_249_2568)" strokeWidth="109" />
            <motion.path variants={pathVariants} d="M1349 216L1948 364" stroke="url(#paint1_linear_249_2568)" strokeWidth="4" />
            <motion.path variants={pathVariants} d="M562 -95L1991 257" stroke="url(#paint2_linear_249_2568)" strokeWidth="4" />
            <motion.path variants={pathVariants} d="M294 -21L1302 229" stroke="url(#paint3_linear_249_2568)" strokeWidth="4" />
            <motion.path variants={pathVariants} d="M1494 9L2093 157" stroke="url(#paint4_linear_249_2568)" strokeWidth="4" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_249_2568" x1="1349" y1="157" x2="1948" y2="157" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F5F5F5" />
                <stop offset="1" stopColor="#EDEDED" />
            </linearGradient>
            <linearGradient id="paint1_linear_249_2568" x1="1349" y1="290" x2="1948" y2="290" gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEEEEE" />
                <stop offset="1" stopColor="#D5D5D5" />
            </linearGradient>
            <linearGradient id="paint2_linear_249_2568" x1="562" y1="81" x2="1991" y2="81" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D5D5D5" />
                <stop offset="0.735788" stopColor="#EEEEEE" />
            </linearGradient>
            <linearGradient id="paint3_linear_249_2568" x1="294" y1="104" x2="1302" y2="104" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D5D5D5" />
                <stop offset="0.645372" stopColor="#F5F5F5" />
            </linearGradient>
            <linearGradient id="paint4_linear_249_2568" x1="1494" y1="83" x2="2093" y2="83" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F5F5F5" />
                <stop offset="1" stopColor="#D5D5D5" />
            </linearGradient>
        </defs>
    </motion.svg>
);
