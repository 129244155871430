import { Button, Divider, Form, Input, Space, Typography, Upload, Spin, UploadProps, message } from "antd";
import axios, { AxiosError } from "axios";
import React, { Fragment } from "react";
import xss from 'xss'
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { Home as icons } from '../../assets/images'
import i18n from '../../assets/locales'
import useMobile from "../../hooks/useMobile";
import classNames from "classnames";

export const requiredRule = { required: true, message: i18n.t('home.section6.form.validate.required') }
export const purifyRule = {
    message: i18n.t('home.section6.form.validate.purify'),
    validator: async function (rule: any, value: string) {
        value = value || ''
        const filterStr = xss(value)
        if (value !== filterStr) {
            throw new Error('Please input valid character.')
        }
    }
}
export const maxLengthRule = (maxLength = 200) => {
    return {
        message: i18n.t('home.section6.form.validate.maxLength', { maxLength }),
        validator: async function (rule: any, value: string) {
            value = value || ''
            if (value.length > maxLength) {
                throw new Error(`The input cannot exceed a maximum of ${maxLength} characters.`)
            }
        }
    }
}

const ContactForm = function () {
    const { t } = useTranslation()
    const { isMobile } = useMobile()
    const [form] = Form.useForm()
    const [loading, setLoading] = React.useState(false)
    const [result, setResult] = React.useState<{ isError: boolean, text: string } | null>(null)

    const parseFormData = (values: {
        name: string,
        message: string,
        email: string
    }) => {
        const formData = new FormData()
        const keys = Object.keys(values) as (keyof typeof values)[]
        for (const key of keys) {
            if (values[key] && !Array.isArray(values[key])) {
                formData.set(key, values[key] as string)
            }
        }
        return formData
    }

    return (
        <Form
            className="form"
            form={form}
            layout="vertical"
            onFinish={async (values: {
                name: string,
                email: string,
                message: string
            }) => {
                setResult(null)
                setLoading(true)
                try {
                    await form.validateFields()
                    const { data: { success, message: msg } } = await axios.post<{ success: boolean, message: string }>('/api/v1/contact_us', parseFormData({
                        ...values
                    }))
                    setLoading(false)
                    if (success) {
                        form.resetFields()
                        setResult({ isError: false, text: i18n.t('home.section6.form.message.success') })
                    } else {
                        setResult({ isError: true, text: msg })
                    }
                } catch (e: any) {
                    let err = (e as Error).message
                    if (e.response && e.response.data && e.response.data.errors) {
                        const errors = e.response.data.errors
                        err = Object.keys(errors).map(key => {
                            let value = errors[key]
                            if (Array.isArray(value)) {
                                value = value.join(',')
                            }
                            return `${key}: ${value}`
                        }).join(';<br/>')
                    }
                    setLoading(false)
                    setResult({ isError: true, text: err })
                }
            }}>
            {isMobile ? (
                <Fragment>
                    <Form.Item required rules={[requiredRule, purifyRule, maxLengthRule(200)]} name={'name'}>
                        <Input placeholder={`${t('home.section6.form.fields.name')}*`} />
                    </Form.Item>
                    <Form.Item required rules={[requiredRule, purifyRule, maxLengthRule(200), { type: 'email', message: i18n.t('home.section6.form.validate.email') }]} name={'email'}>
                        <Input placeholder={`${t('home.section6.form.fields.email')}*`} />
                    </Form.Item>
                </Fragment>
            ) : (
                <Space className='row-1'>
                    <Form.Item required rules={[requiredRule, purifyRule, maxLengthRule(200)]} name={'name'}>
                        <Input placeholder={`${t('home.section6.form.fields.name')}*`} />
                    </Form.Item>
                    <Form.Item required rules={[requiredRule, purifyRule, maxLengthRule(200), { type: 'email', message: i18n.t('home.section6.form.validate.email') }]} name={'email'}>
                        <Input placeholder={`${t('home.section6.form.fields.email')}*`} />
                    </Form.Item>
                </Space>
            )}

            <Form.Item required rules={[requiredRule, purifyRule, maxLengthRule(200)]} name={'company_name'}>
                <Input placeholder={`${t('home.section6.form.fields.company')}*`} />
            </Form.Item>
            <Form.Item required rules={[requiredRule, purifyRule, maxLengthRule(10000)]} name={'message'}>
                <Input.TextArea placeholder={`${t('home.section6.form.fields.message')}*`} rows={3} autoSize={{ minRows: 3 }} />
            </Form.Item>
            <Form.Item className="submit">
                <div className={classNames('submit-result', result ? result.isError ? "danger" : 'success' : '')} dangerouslySetInnerHTML={{ __html: result?.text || '' }}></div>
                <Button size="large" type='primary' htmlType="submit">
                    <Space>
                        {loading && (<Spin indicator={<LoadingOutlined style={{ color: '#FFF' }} spin />} />)}
                        <span>Submit</span>
                        <img src={icons.section6.Arrow} />
                    </Space>
                </Button>
            </Form.Item>
        </Form>
    )
}

export default ContactForm