import React, { Fragment, ReactElement, ReactNode, ElementType, HTMLAttributes } from 'react'


interface ConfigurableComponentProps<T> {
    as?: ElementType;
    data: T[],
    columns: number,
    children: {
        (item: T, i: number): ReactElement
    },
    style?: React.CSSProperties,
    columnStretch?: boolean,
    [key: string]: any;
}

export const Grid = <T,>({
    as: Component = 'div',
    data,
    createItems,
    columns,
    columnStretch,
    style,
    children,
    ...rest
}: ConfigurableComponentProps<T>) => {
    const rows = (data.length - data.length % columns) / columns + 1
    const gridStyles: React.CSSProperties = {
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridTemplateRows: `repeat(${rows}), 1fr`,
        gridAutoRows: columnStretch ? '1fr' : 'auto',
        alignItems: 'stretch',
    }
    const mergedStyle = Object.assign({}, gridStyles, style)
    return (
        <Component {...rest} style={mergedStyle}>
            {data.map((item, i) => {
                return (
                    <Fragment key={i}>{children(item, i)}</Fragment>
                )
            })}
        </Component>
    );
};
